export enum GetNumberOfUnitType {
  API = 'API',
  MANUAL = 'MANUAL',
}

export enum PmSoftwares {
  ENTRATA = 'Entrata',
  YARDI = 'Yardi',
  RENT_MANAGER = 'Rent Manager',
  REAL_PAGE = 'RealPage',
  RESMAN = 'ResMan',
}

export enum DaysOfWeek {
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
}

export enum CompaniesSortBy {
  COMPANY = 'company',
  NUMBER_OF_UNITS = 'numberOfUnits',
  ORGANIZATION = 'organization',
  TWILIO_NUMBER = 'twilioNumber',
  CREATED_AT = 'createdAt',
  USERS = 'users',
  SUBSCRIPTION = 'subscription',
}

export enum SettlementAmountHandleMethod {
  MANUAL = 'Manual',
  AUTOMATIC = 'Automatic',
}

export enum DebtCollectionPaymentLinkHandleMethod {
  COMPANY = 'company',
  STRIPE = 'stripe',
}

export enum ReminderMessagesCommunicationMethods {
  EMAIL = 'email',
  SMS = 'sms',
  CALL = 'call',
}

export enum ReminderMessagesMethods {
  GEN_AI = 'gen AI',
  SCHEDULER = 'scheduler',
  GRAPH = 'graph',
}

export enum PropertyManagentFeaturesKeyNames {
  RENT_COLLECTION = 'rentCollection',
  DEBT_COLLECTION = 'debtCollection',
  RENEWALS = 'renewals',
  LEASING = 'leasing',
}

export enum CompanyTabs {
  LEFT_MENU_SETTINGS = 'Left Menu Settings',
  API_CREDENTIALS = 'API Credentials',
  RENT_COLLECTION = 'Rent Collection',
  RENEWALS = 'Renewals',
  LEASING = 'Leasing',
  DEBT_COLLECTION = 'Debt Collection',
  REPORTS = 'Reports',
  AI_MODULES = 'AI Modules',
  WORKING_HOURS = 'Working Hours',
}

export enum RenewalsMethod {
  MANUAL = 'manual',
  LRO_STATUS = 'LRO status',
}

export enum CustomersUserTypes {
  ORGANIZATION_MANAGER = 'Organization Manager',
  MANAGER = 'Manager',
  USER = 'User',
  PROPERTY_MANAGER = 'Property Manager',
  ASSISTANT_PROPERTY_MANAGER = 'Assistant Property Manager',
  LEASING_AGENT = 'Leasing Agent',
}
