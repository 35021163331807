import { Injectable } from '@angular/core';
import { ToolsService } from './tools.service';
import { Company, LoadOrganizationsResponse, Organization, UserDataForAdmin } from '../types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnabledService } from '../enums';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

interface CalendarHolidays {
  name?: string;
  date?: Date | string;
  federal?: boolean;
  companyID?: string;
  edit?: boolean;
  _id?: string;
}

interface CalendarDays {
  start: string;
  end: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  _id?: string;
}
@UntilDestroy()
@Injectable()
export class MainService {
  public rowsPerPageAdminSettings: number = JSON.parse(localStorage.getItem('rowsPerPageAdminSettings') || '100');

  chatChartData: any = [{ data: [], label: 'Chats' }];
  chatChartLabels: any = [];
  chatChartColors: any = [
    {
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  fallbackChartData: any = [{ data: [], label: 'Fallbacks' }];
  fallbackChartLabels: any = [];
  fallbackChartColors: any = [
    {
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  maintenanceChartData: any = [{ data: [0, 0, 0], label: 'Maintenance' }];
  maintenanceChartLabels: any = ['Professional', 'Business', 'Elite'];
  // maintenanceChartLabels: any = [];
  maintenanceChartColors: any = [
    {
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  refreshChatChart: any = true;
  refreshFallbacksChart: any = true;
  refreshMaintenanceChart: any = true;

  NS_URL: string = environment.NS_URL;

  userData: any = JSON.parse(localStorage.getItem('userData'));
  userID: string = localStorage.getItem('userID');
  firebaseUserID: string = localStorage.getItem('firebaseUserID');
  totalRequests = 0;

  requests: any = [];
  landlordsCount: any = 0;
  totalFallbacks24: any = 0;
  eliteUsersCount: any = 0;
  businessUsersCount: any = 0;
  totalNumberOfUnits: any = 0;
  lastMonthLandlords: any = 0;
  landlordPlans: any = ['FREE', 'PROFESSIONAL', 'BUSINESS', 'ELITE'];
  managementSoftware: any = [
    'None',
    'Appfolio',
    'Entrata',
    'MRI',
    'RealPage',
    'Rent Manager',
    'ResMan',
    'Yardi',
    'Other',
  ];
  services: EnabledService[] = Object.values(EnabledService);
  last24HoursLandlords: any = 0;
  setting: any = {
    organizationIDs: [],
    managementSoftware: this.managementSoftware,
    landlordPlans: this.landlordPlans,
    service: this.services,
  };
  subUsers: any = [];
  companyName: any;
  organizations: Organization[] = [];
  companies: Company[] = [];
  states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NC',
    'ND',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'DC',
    'WA',
    'WV',
    'WI',
    'WY',
  ];
  EMAIL_SERVICE_BASE_URL = environment.EMAIL_SERVICE_BASE_URL;

  constructor(
    public http: HttpClient,
    public tools: ToolsService
  ) {
    this.loadUserData();
    this.setDateForChart();
    this.setDateForChart2();
  }

  public updateUserData(): void {
    this.http
      .put(`${this.NS_URL}/users/admin`, this.userData)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.loadUserData());
  }

  setDateForChart() {
    for (let i = 0; i < 90; i++) {
      this.fallbackChartData[0].data.push(0);
      const today = new Date();
      const priorDate = new Date().setDate(today.getDate() - i);
      this.fallbackChartLabels.unshift(
        this.tools.month[new Date(priorDate).getMonth()] + ' ' + new Date(priorDate).getDate()
      );
    }
  }
  setDateForChart2() {
    this.chatChartData[0].data = [];
    this.chatChartLabels = [];
    for (let i = 0; i < 30; i++) {
      this.chatChartData[0].data.push(0);
      const today = new Date();
      const priorDate = new Date().setDate(today.getDate() - i);
      this.chatChartLabels.unshift(new Date(priorDate).getDate());
    }
  }

  loadUserData() {
    this.http
      .get<{ data: UserDataForAdmin }>(`${this.tools.NS_URL}/users/get-admin?firebaseUserID=${this.firebaseUserID}`)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res.data) {
          this.userData = res.data;
          localStorage.setItem('lastLogin', Date.now().toString());
          localStorage.setItem('userData', JSON.stringify(this.userData));
          this.userData.fullName = this.userData.firstName + ' ' + this.userData.lastName;
          this.userData.landlordID = this.userData.landlordID || this.userData._id;
        }
      });
  }

  public loadOrganizations(): Observable<LoadOrganizationsResponse> {
    return this.http.get<LoadOrganizationsResponse>(this.NS_URL + '/admin/organizations').pipe(
      tap((res) => {
        this.organizations = res.data;
      })
    );
  }

  loadSettingData(companyID) {
    return new Promise((resolve: any) => {
      const o = { companyID };
      this.http
        .get(this.tools.EURIC(this.NS_URL + `/admin/companies/${companyID}/settings`, o))
        .subscribe((res: any) => {
          resolve(res.data);
        });
    });
  }

  DisableCompanyConversation(companyID: any) {
    const disabledCompanies = ['60192a66c7b4183e949ccc22'];
    return disabledCompanies.includes(companyID) ? true : false;
  }

  getSmsConsent(companyID) {
    return new Promise((resolve) => {
      this.http.get(this.NS_URL + '/calendar/get-sms-consent?companyID=' + companyID).subscribe((res: any) => {
        resolve(res.data || { name: '', notification: false });
      });
    });
  }

  getHolidays(companyID: string) {
    return new Promise<CalendarHolidays[]>((resolve) => {
      this.http.get(this.NS_URL + '/calendar/get-holidays?companyID=' + companyID).subscribe((res: any) => {
        const data = res.data.map((holiday) => {
          const date = holiday.date ? new Date(holiday.date).toLocaleDateString('en-US') : '';
          return { ...holiday, date };
        });
        resolve(data);
      });
    });
  }

  getDays(companyID: string) {
    return new Promise<CalendarDays>((resolve) => {
      this.http.get(this.NS_URL + '/calendar/get-days?companyID=' + companyID).subscribe((res: any) => {
        resolve(res.data);
      });
    });
  }

  loadCompany(companyID: string) {
    return new Promise((resolve) => {
      this.http.get(this.NS_URL + '/companies/get-company-by-id?_id=' + companyID).subscribe((res: any) => {
        if (res.data) {
          resolve(res.data);
        }
      });
    });
  }

  public updatePaginationNumber(): void {
    localStorage.setItem('rowsPerPageAdminSettings', this.rowsPerPageAdminSettings.toString());
  }
}
