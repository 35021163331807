import { Routes } from '@angular/router';
import { AccountGuard } from '../guards/account.guard';
import { MainGuard } from '../guards/main.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'account',
    canActivate: [AccountGuard],
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        loadComponent: () => import('../components/login/login.component').then((m) => m.LoginComponent),
      },
    ],
  },
  {
    path: 'main',
    loadChildren: () => import('./main.routes').then((m) => m.MAIN_ROUTES),
    canActivateChild: [MainGuard],
  },
  {
    path: '**',
    redirectTo: 'account/login',
    pathMatch: 'full',
  },
];
